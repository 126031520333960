@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

.parallax-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  will-change: transform;
}

.content {
  position: relative;
  z-index: 1;
}
