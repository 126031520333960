.hero {
  height: 100vh;
  color: var(--light-bg);
  position: relative;
  overflow: hidden;
}

.parallax-bg {
  background-image: url('../assets/images/hero-bg.jpg');
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: 0;
}

.brand-name {
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  opacity: 0;
  animation: fadeInDown 1s ease 0.5s forwards;
}

.brand-name h2 {
  font-size: 1.5rem;
  letter-spacing: 3px;
  font-weight: 500;
  color: var(--secondary-color);
  text-transform: uppercase;
  display: inline-block;
  padding: 0.8rem 3rem;
  background: rgba(44, 14, 55, 0.85);
  border-radius: 4px;
  position: relative;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  animation: glow 2s ease-in-out infinite;
}

.brand-name h2::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 6px;
  background: linear-gradient(45deg,
    transparent 30%,
    rgba(255, 255, 255, 0.2) 45%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.2) 55%,
    transparent 70%
  );
  filter: blur(2px);
  animation: borderGlow 4s linear infinite;
}

@keyframes glow {
  0%, 100% {
    text-shadow:
      0 0 10px rgba(255, 255, 255, 0.3),
      0 0 20px rgba(255, 255, 255, 0.2),
      0 0 30px rgba(255, 255, 255, 0.1);
    box-shadow:
      0 0 20px rgba(255, 255, 255, 0.2),
      0 0 30px rgba(255, 255, 255, 0.1);
  }
  50% {
    text-shadow:
      0 0 20px rgba(255, 255, 255, 0.4),
      0 0 30px rgba(255, 255, 255, 0.3),
      0 0 40px rgba(255, 255, 255, 0.2);
    box-shadow:
      0 0 30px rgba(255, 255, 255, 0.3),
      0 0 40px rgba(255, 255, 255, 0.2);
  }
}

@keyframes borderGlow {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.hero-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    transparent,
    rgba(44, 14, 55, 0.4) 20%,
    rgba(44, 14, 55, 0.8)
  );
  padding: 6rem 0 2rem 0;
  z-index: 2;
}

.hero-text {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: left;
}

.hero h1 {
  font-size: 3rem;
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: 0.5rem;
  animation: fadeInUp 1s ease;
  font-weight: 600;
}

.subtitle {
  font-size: 1.4rem;
  font-family: 'Inter', sans-serif;
  margin: 0;
  animation: fadeInUp 1s ease 0.3s;
  animation-fill-mode: both;
  opacity: 0.9;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .brand-name h2 {
    font-size: 1.2rem;
    padding: 0.4rem 1.5rem;
  }

  .hero-text {
    text-align: center;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .hero-bottom-content {
    padding: 4rem 0 1.5rem 0;
  }
}