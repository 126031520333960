.handloom-section {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-bg);
  perspective: 1px;
}

.handloom-bg {
  background-image: url('../assets/images/toss-bg.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  transform-style: preserve-3d;
  will-change: transform;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(44, 14, 55, 0.2),
    rgba(44, 14, 55, 0.4)
  );
  z-index: 1;
}

.handloom-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 2rem;
}

.section-title {
  font-size: 4rem;
  margin-bottom: 1rem;
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 600;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.section-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 2px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  transition-delay: 0.2s;
}

.visible .section-title,
.visible .section-subtitle {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .section-title {
    font-size: 3rem;
  }

  .section-subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .section-title {
    font-size: 2.5rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }
}