.coming-soon {
  min-height: 100vh;
  background: linear-gradient(135deg, var(--primary-color), var(--footer-bg));
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-bg);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.coming-soon::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.05) 10px,
    rgba(255, 255, 255, 0.05) 20px
  );
  animation: backgroundMove 20s linear infinite;
}

.coming-soon-content {
  max-width: 600px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.back-home {
  position: absolute;
  top: -60px;
  left: 0;
  color: var(--light-bg);
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.back-home:hover {
  color: var(--secondary-color);
}

.coming-soon h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
  font-weight: 800;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.countdown-wrapper {
  margin: 3rem 0;
}

.launch-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.geometric-shapes {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem 0;
}

.shape {
  width: 30px;
  height: 30px;
  background: var(--secondary-color);
  animation: bounce 1.5s infinite;
}

.shape:nth-child(1) {
  animation-delay: 0s;
}

.shape:nth-child(2) {
  animation-delay: 0.2s;
}

.shape:nth-child(3) {
  animation-delay: 0.4s;
}

.description {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  color: rgba(255, 255, 255, 0.9);
}

.notify-form {
  display: flex;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.notify-form input {
  flex: 1;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.1);
  color: var(--light-bg);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.notify-form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.notify-form button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  background: var(--secondary-color);
  color: var(--light-bg);
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.notify-form button:hover {
  transform: translateY(-2px);
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes backgroundMove {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (max-width: 768px) {
  .coming-soon h1 {
    font-size: 3rem;
  }

  .notify-form {
    flex-direction: column;
  }

  .notify-form button {
    width: 100%;
  }
}